import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import QualityAnalysisServiceBanner from '../images/quality_analysis_service_features.svg'
import QualityAnalysisServiceFeature from '../images/qa_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const QualityAnalysis = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/quality-analysis" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Quality Analysis Services" description='eWise - Quality Analysis Services'/>
 <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-45 md:text-50 lg:text-55 xl:text-60 2xl:text-8xl block">Quality Analysis</strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-32 xl:text-34 2xl:text-46">Services</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto max-w-350 md:max-w-350 lg:max-w-470 m-auto lazyload" loading="lazy" data-src={QualityAnalysisServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
  <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">For eWise, quality is more than just checking for bugs!<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p>With continuous evolution in business environments that includes increasing application integration, use of service-oriented architecture and system complexity, organizations have never been more focused on the need to strike the balance between quality assurance testing services and speed up time to market.</p>
            </div>

          </div>
      </div>
  </section>
  <section className="relative pb-8 sm:pb-10 lg:pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full lg:w-1/2 px-4 order-2 lg:order-1">
                  
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why third-party Quality <br class="hidden xl:inline-block"></br>Assurance?<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Focus on the main business rather than multiple checks and corrections</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Reduced costs owing to less wastage and re-working of faulty products</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get your platform thoroughly qualified with best practices and experts</li>
                        <li className="pl-10 relative">Be assured of unbiased quality check and guaranteed performance</li>
                    </ul>
                </div>
                <div className="features_right w-full lg:w-1/2 flex justify-center px-4 order-1 lg:order-2">
                    <img className="object-contain max-w-330 md:max-w-330 xl:max-w-420 mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={QualityAnalysisServiceFeature} alt="iphone"/>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Our range of Quality Assurance services to obtain perfection<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Automation Testing</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Our professional QA teams use high-quality frameworks and platforms to perform automation testing that assures speedy and reliable performance.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Usability Testing</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">With our end-to-end usability testing services, you can improve and enhance the critical flows of your web platform by determining the faults and failings in the user experience.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Core Testing</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">We have exclusive expertise in core testing! With deep industry knowledge and experience, our testers provide innovative and business-driven testing solutions.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Security Testing</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Gain valuable insight into your security ecosystem with holistic reports and secure your operational systems as expected.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Performance Testing</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Assure the best performance to your customers for a happy and satisfied experience. eWise’s testing application performance pinpoints every angle of the product under a particular workload.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 ">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Mobile Testing</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">We test your application on mobile platforms so that your user experience has flawless functionalities and impeccability for users under varying network conditions, that too at competitive prices.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">How Does eWise Work?</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">With rich experience and deep industry expertise, we adopt the best practices and latest technologies to assure you of optimum flexibility. Our focus is on choosing the best test methodology to deliver a huge advantage, ensuring both quality and timely delivery.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Strategy</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">We build a test strategy that determines the testing approach of a software development cycle. It is designed to address the core issues of the testing process.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Planning</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">It verifies and ensures that the product meets its defined specification and other requirements. It pinpoints and defines the defects, risks, test cases, and test schedules.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Procedures</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">With this, eWises’ QA assures that the product is working perfectly fine, without any functionality bugs and faults.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Validation</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">It validates the software, enabling speedy time-to-the-market. Through validation, we enable our customers to achieve greater ROI with the highest quality standards of their products.</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why eWise for Quality Assurance?<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="px-4 pb-4">Our testing experts efficiently collaborate with you to offer high-performing, reliable and cost-efficient industry solutions. Quality has always been our priority, hence, we ensure our quality assurance brings agility, creates value, and addresses requirements of complex web development projects. Stay digitally assured with eWise QA!</p>
                    <h5 className="pb-4 px-4 text-lg md:text-xl lg:text-22">Comprehensive Testing Services, Detect Bugs Faster</h5>
                    <p className="px-4 pb-4">We follow an agile and end-to-end approach for Quality Assurance testing solutions enabling companies to reimagine business workflows, reinvent user experiences, and redefine mobility for enhanced business outcomes.</p>
                    <p className="px-4">We promise you the best performing products with the highest Quality Assurance standards!</p>
                </div>
            </div>
          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Service Circle"/>
  </section>
    </Layout>
  )
}

export default QualityAnalysis